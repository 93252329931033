import { forwardRef, MutableRefObject, useEffect, useRef, useState } from "react";

interface TextEditorProps {
  autofocus?: boolean;
  text: string;
  sanitize?: (newText: string) => string;
  onStopEditing: (newText: string) => void;
}

/**
 * An editable element (an input that looks like plain text) where a <p> is hidden behind the input
 * with identical text so that the input width can adjust like plain text (by default it won't).
 */
const TextEditor = forwardRef<HTMLInputElement, TextEditorProps>(
  ({ autofocus, text, sanitize = (x) => x, onStopEditing }, ref) => {
    const [currentText, setCurrentText] = useState(text);
    const defaultRef = useRef<HTMLInputElement>();
    const inputRef = ref || defaultRef;

    useEffect(() => {
      const onKeyDown = (evt: KeyboardEvent): void => {
        switch (evt.key) {
          case "Escape":
            setCurrentText(text);

            window.requestAnimationFrame(() =>
              (inputRef as MutableRefObject<HTMLInputElement>).current.blur(),
            );

            return;
          case "Enter":
            return (inputRef as MutableRefObject<HTMLInputElement>).current.blur();
          default:
            return null;
        }
      };

      document.addEventListener("keydown", onKeyDown);

      return () => {
        document.removeEventListener("keydown", onKeyDown);
      };
    }, []);

    return (
      <span className="TextEditor">
        <p>{currentText || "\xa0"}</p>
        <input
          ref={inputRef}
          autoFocus={autofocus}
          onBlur={() => {
            const sanitizedText = sanitize(currentText.trim());

            if (!sanitizedText) {
              // revert; don't allow empties
              setCurrentText(text);
            } else if (sanitizedText !== currentText) {
              setCurrentText(sanitizedText);
            }

            onStopEditing(sanitizedText || text);
          }}
          onChange={(evt) => setCurrentText(evt.target.value)}
          type="text"
          value={currentText}
        />
      </span>
    );
  },
);

export default TextEditor;
